import { BusinessDTO, CreateBusinessDTO } from "~/types";
import {
  AlterUserRoleDTO,
  CreateUserDTO,
  DeleteUserDTO,
  LoginResponse,
  LoginUserDTO,
  UpdateUserDTO,
  UserDTO,
  UsersQueryResponse,
} from "~/types/authentication";

import { baseApi } from "../baseApi";

//Mutation should be used for anything which changes the data on the server

export const powerBusinessApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({

    createBusinessForUser: builder.mutation<void, string>({
      query: (userId) => ({
        url: `/PowerBusiness/createBusinessForUser?userId=${userId}`,
        method: "POST",
        credentials: "include",
        //Mutations require the invalidatesTags instead of providesTags
      invalidatesTags:["Business"],
        
      }),
      invalidatesTags:["Business"],
 
      transformResponse: (response: void, meta) => {
        return response;
      },
      transformErrorResponse: (response, meta, arg) => {

        return {
          originalArg: arg,
          error: response,
        };
      },
    }),
 


   
 

    updateBusinessForUser: builder.mutation<void, BusinessDTO>({
      query: (business) => ({
        url: `/PowerBusiness/updateBusinessForUser`,
        method: "PUT",
        body: business,
        credentials: "include",
      invalidatesTags: ["Business"],

      }),
      invalidatesTags:["Business"],

    }),
    deleteBusinessForUser: builder.mutation<void, string>({
      query: (businessId) => ({
        url: `/PowerBusiness/deleteBusinessForUser?businessId=${businessId}`,
        method: "DELETE",
        credentials: "include",
      invalidatesTags: ["Business"],

      }),
      invalidatesTags: ["Business"],

      transformResponse: (response: void, meta) => {
        return response;
      },
      transformErrorResponse: (response, meta, arg) => {
        return {
          originalArg: arg,
          error: response,
        };
      },
    }),

   
   
   
  }),
});

export const {
    useCreateBusinessForUserMutation,
    useDeleteBusinessForUserMutation,
    useUpdateBusinessForUserMutation
} = powerBusinessApi;
