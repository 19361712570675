import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query";
import { Mutex } from "async-mutex";
import { loggedOut, tokenReceived } from "./authSlice";
import { RootState } from "./stores";
import { LoginResponse } from "~/types/authentication";

//const baseUrl = `${process.env.REACT_APP_SERVER_ENDPOINT}/api/`;
// Create a new mutex
const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
  baseUrl: "http://127.0.0.1:8080",
  //baseUrl: "https://rainierapps.net/api/",
  credentials: 'include',
  prepareHeaders: async (headers, { getState }) => {
    // By default, if we have a token in the store, let's use that for authenticated requests

    //await (getState() as RootState).auth.userToken;
    const token : string =  localStorage.getItem('token') || "";
    if (token.length > 0) {
      
      headers.set("authorization", `Bearer ${token}`);
      headers.set("refreshToken", `Bearer ${token}`);

    }
// console.log("Setting headers.. " + token)
    return headers;
  },
});

const customFetchBase: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock();
  console.log('sending request for ' + JSON.stringify(api) + " ...");
  let result = await baseQuery(args, api, extraOptions);

  if (result.meta?.response?.status == 401) {
    //Oops. jwt invalid or expired, try to obtain new jwt token with refresh token
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();

      try {
        //See what happens when we try to hit the refresh endpoint
        const refreshResult = await baseQuery(
          { credentials: "include", url: "Auth/refresh" },
          api,
          extraOptions
        );
        if (!refreshResult.error as boolean) {
          //OK, refresh token/ jwt were a valid pair
          api.dispatch(tokenReceived((refreshResult.data as LoginResponse).token));
          // Retry the initial query
          result = await baseQuery(args, api, extraOptions);
        } else {
          //Invalid refresh/jwt token pair, for whatever reason
          api.dispatch(loggedOut());
         window.location.href = "/login";
        }
      } finally {
        // release must be called once the mutex should be released again.
        release();
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }

  return result;
};

export default customFetchBase;
