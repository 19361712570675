import React, { useCallback, useEffect, useState } from "react";
import {
  MDBContainer,
  MDBNavbar,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBBtn,
  MDBInputGroup,
  // MDBInputGroupText,
  // MDBInputGroupElement,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBBadge,
  MDBSwitch,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "shared";
import { useCookies } from "react-cookie";
import Switch from "react-switch";
interface ChildProps {
  toggleSideNav: React.Dispatch<React.SetStateAction<boolean>>;
  sideNavState: boolean;
}

export default function Navbar(props: ChildProps) {
  const darkModeContext = useAppSelector((state) => state.theme);
  const signedIn = useAppSelector((state) => state.auth.userToken);
  const [cookies, setCookie, removeCookie] = useCookies(["dark"]);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  function setDarkModeAndToggleCheck() {
    let darkMode = !darkModeChecked;
    setDarkModeChecked(darkMode);
    dispatch({ type: "theme/toggleTheme", payload: darkMode });
    setCookie("dark", darkMode);
  }

  const [darkModeChecked, setDarkModeChecked] = useState<boolean>(
    darkModeContext.darkMode
  );

  const [isNarrowScreen, setIsNarrowScreen] = useState(false);

  useEffect(() => {
    let darkBool: boolean = cookies["dark"] === "true" ? true : false;
    setDarkModeChecked(darkBool);
  }, []);

  const checkResize = () => {
    if (window.outerWidth < 1400) {
      setIsNarrowScreen(true);
    } else {
      setIsNarrowScreen(false);
    }
  };

  useEffect(() => {
    checkResize();

    window.addEventListener("resize", checkResize);

    return () => {
      window.removeEventListener("resize", checkResize);
    };
  }, []);

  return (
    <MDBNavbar className=".shadow-0"
      id="main-navbar"
      expand="lg"
      fixed="top"
      style={{ maxWidth: "100vw", boxShadow: "none" }}
    >
      <MDBContainer fluid>
        <MDBNavbarNav>
          <div className="d-flex flex-row justify-content-end">
            <MDBNavbarItem>
              <MDBRow style={{ right: "24px", marginTop: "8px" }}>
                <MDBCol>
                  <label>
                    <Switch
                      id="darkModeSwitch"
                      onChange={setDarkModeAndToggleCheck}
                      checked={darkModeChecked}
                      uncheckedIcon={
                        <MDBIcon
                          icon="sun"
                          style={{ marginLeft: "6px", marginTop: "5px" }}
                        ></MDBIcon>
                      }
                      checkedIcon={
                        <MDBIcon
                          icon="moon"
                          style={{ marginLeft: "12px", marginTop: "5px" }}
                        ></MDBIcon>
                      }
                      onColor={"#00008B"}
                      offColor={"#808080"}
                    />
                  </label>
                </MDBCol>
              </MDBRow>
            </MDBNavbarItem>
            {isNarrowScreen && (
              <MDBNavbarItem style={{ marginLeft: 0 }}>
                <MDBRow style={{ marginRight: "24px", marginTop: "8px" }}>
                  {darkModeChecked ? (
                    <MDBBtn
                      hidden={isNarrowScreen}
                      onClick={() => props.toggleSideNav(!props.sideNavState)}
                      className="shadow-0 p-0 me-3 d-block"
                      style={{ backgroundColor: "transparent" }}
                    >
                      <MDBIcon icon="bars" size="lg" fas />
                    </MDBBtn>
                  ) : (
                    <MDBBtn
                      hidden={isNarrowScreen}
                      onClick={() => props.toggleSideNav(!props.sideNavState)}
                      className="shadow-0 p-0 me-3 d-block"
                      color="light"
                    >
                      <MDBIcon icon="bars" size="lg" fas />
                    </MDBBtn>
                  )}
                </MDBRow>
              </MDBNavbarItem>
            )}
          </div>
          <div className="d-flex flex-row justify-content-start"></div>
        </MDBNavbarNav>
      </MDBContainer>
    </MDBNavbar>
  );
}
