import React, { useState, useRef, useEffect, Suspense } from "react";
import {
  MDBSideNav,
  MDBSideNavMenu,
  MDBSideNavItem,
  MDBSideNavLink,
  MDBSideNavCollapse,
  MDBIcon,
  MDBRipple,
} from "mdb-react-ui-kit";
import { Link, useNavigate } from "react-router-dom";
import { RootState, useAppSelector } from "shared";
import { AppRoutes } from "providers";
import Footer from "components/footers/Footer";
import logo from "assets/rainier_logo.svg"; // with import
import { motion } from "framer-motion";
import CozyHouse from "assets/cozyHouse.svg";

export type sideNavParams = {
  basicOpen: boolean;
  // eslint-disable-next-line @typescript-eslint/ban-types
  setBasicOpen: Function;
};

export default function Sidenav(params: sideNavParams) {
  const signedIn = useAppSelector((state) => state.auth.userToken);

  // const isAuth = useAppSelector(
  //   (state: RootState ) => state.auth.authenticated
  // );
  const [mode, setMode] = useState("side");
  const [profileCollapse, setProfileCollapse] = useState(false);

  const [basicCollapse1, setBasicCollapse1] = useState(false);
  const [adminCollapse, setAdminCollapse] = useState(false);

  const navigate = useNavigate();
  const outerWidth = useRef<number | null>(null);

  const handleProfileCollapse = () => {
    console.log("setting basic collapse");
    if (adminCollapse && !basicCollapse1) {
      setAdminCollapse(false);
    }

    setProfileCollapse((value) => !value);
  };
  const handleBasicCollapse1 = () => {
    console.log("setting basic collapse");
    if (adminCollapse && !basicCollapse1) {
      setAdminCollapse(false);
    }

    setBasicCollapse1((value) => !value);
  };

  const handleAdminCollapse = () => {
    if (basicCollapse1 && !adminCollapse) {
      setBasicCollapse1(false);
    }

    setAdminCollapse((value) => !value);
  };
  const sidenavContent = useRef(null);

  const [container, setContainer] = useState<any>();
  const checkResize = () => {
    if (window.outerWidth === outerWidth.current) {
      return;
    }
    outerWidth.current = window.outerWidth;

    if (window.outerWidth < 1100) {
      setMode("over");
      params.setBasicOpen(false);
    } else {
      setMode("side");
      params.setBasicOpen(true);
    }
  };

  useEffect(() => {}, []);
  useEffect(() => {
    checkResize();
    setContainer(sidenavContent.current);

    window.addEventListener("resize", checkResize);

    return () => {
      window.removeEventListener("resize", checkResize);
    };
  }, []);

  return (
    <>
      <MDBSideNav
        isOpen={params.basicOpen}
        getOpenState={(e) => params.setBasicOpen(e)}
        mode={mode}
        closeOnEsc={mode == "over"}
        backdrop={mode == "over"}
        constant={false}
        contentRef={container}
        right
        
      >
        <MDBSideNavMenu 
 >
          <MDBSideNavItem className="text-center" style={{ marginLeft: 24 }}>
            <img
              src={logo}
              style={{ maxWidth: 48, marginTop: 28 }}
              className="img-fluid shadow-4"
              alt="..."
            />
            <p>Rainier Apps</p>
          </MDBSideNavItem>
          <MDBSideNavItem>
            <motion.div
              whileHover={{
                x: 50,
                scale: 1.2,

                transition: { duration: 0.25 },
              }}
              whileTap={{ scale: 0.9 }}
              style={{ width: "100%" }}
            >
              <Link
                to="/"
                className="sidenav-link"
                style={{ backgroundColor: "transparent" }}
              >
                <MDBIcon fas icon="house" className="fa-fw me-3" />
                Home
              </Link>
            </motion.div>
          </MDBSideNavItem>
          <MDBSideNavItem>
            <motion.div
              whileHover={{
                x: 50,
                scale: 1.2,

                transition: { duration: 0.25 },
              }}
              whileTap={{ scale: 0.9 }}
              style={{ width: "100%" }}
            >
              <Link
                to="/Pricing"
                className="sidenav-link"
                style={{ backgroundColor: "transparent" }}
              >
                <MDBIcon fas icon="tag" className="fa-fw me-3" />
                Pricing
              </Link>
            </motion.div>
          </MDBSideNavItem>
          <MDBSideNavItem>
            <motion.div
              whileHover={{
                x: 50,
                scale: 1.2,

                transition: { duration: 0.25 },
              }}
              whileTap={{ scale: 0.9 }}
              style={{ width: "100%" }}
            >
              <Link
                to="/portfolio"
                className="sidenav-link"
                style={{ backgroundColor: "transparent" }}
              >
                <MDBIcon fas icon="book" className="fa-fw me-3" />
                Portfolio
              </Link>
            </motion.div>
          </MDBSideNavItem>
          <MDBSideNavItem>
            <motion.div
              whileHover={{
                x: 50,
                scale: 1.2,

                transition: { duration: 0.25 },
              }}
              whileTap={{ scale: 0.9 }}
              style={{ width: "100%" }}
            >
              <Link
                to="/about"
                className="sidenav-link"
                style={{ backgroundColor: "transparent" }}
              >
                <MDBIcon fas icon="question" className="fa-fw me-3" />
                About
              </Link>
            </motion.div>
          </MDBSideNavItem>
          <MDBSideNavItem>
            <motion.div
              whileHover={{
                x: 50,
                scale: 1.2,

                transition: { duration: 0.25 },
              }}
              whileTap={{ scale: 0.9 }}
              style={{ width: "100%" }}
            >
              <Link
                to="/privacy"
                className="sidenav-link"
                style={{ backgroundColor: "transparent" }}
              >
                <MDBIcon fas icon="shield" className="fa-fw me-3" />
                Privacy
              </Link>
            </motion.div>
          </MDBSideNavItem>
      
          

          {signedIn ? (
            <div></div>
          ) : (
            <MDBSideNavItem>
              <motion.div
                whileHover={{
                  x: 50,
                  scale: 1.2,
                  transition: { duration: 0.25 },
                }}
                whileTap={{ scale: 0.9 }}
                style={{ width: "100%" }}
              >
                <Link
                  to="/login"
                  className="sidenav-link"
                  style={{ backgroundColor: "transparent" }}
                >
                  <MDBIcon fas icon="sign-in-alt" className="fa-fw me-3" />
                  Login
                </Link>
              </motion.div>
            </MDBSideNavItem>
          )}

          {signedIn ? (
            <>
              <MDBSideNavItem>
                <motion.div
                  whileHover={{
                    x: 50,
                    scale: 1.2,
                    transition: { duration: 0.25 },
                  }}
                  whileTap={{ scale: 0.9 }}
                  style={{ width: "100%" }}
                >
                  <MDBSideNavLink
                    onClick={() => navigate("/dashboard")}
                    style={{ backgroundColor: "transparent" }}
                  >
                    <MDBIcon fas icon="map" className="fa-fw me-3" />
                    Business DashBoard
                  </MDBSideNavLink>
                </motion.div>
              </MDBSideNavItem>
              <MDBSideNavItem>
                <motion.div
                  whileHover={{
                    x: 50,
                    scale: 1.2,
                    transition: { duration: 0.25 },
                  }}
                  whileTap={{ scale: 0.9 }}
                  style={{ width: "100%" }}
                >
                  <MDBSideNavLink
                    icon="angle-down"
                    onClick={handleBasicCollapse1}
                  >
                    <MDBIcon fas icon="person" className="fa-fw me-3" />
                    Account
                  </MDBSideNavLink>
                </motion.div>
                <MDBSideNavCollapse show={basicCollapse1}>
                  <motion.div
                    whileHover={{
                      x: 50,
                      scale: 1.2,
                      transition: { duration: 0.25 },
                    }}
                    whileTap={{ scale: 0.9 }}
                    style={{ width: "100%" }}
                  >
                    <Link className="sidenav-link" to="/changePassword">
                      Change password
                    </Link>
                  </motion.div>
                  <motion.div
                    whileHover={{
                      x: 50,
                      scale: 1.2,
                      transition: { duration: 0.25 },
                    }}
                    whileTap={{ scale: 0.9 }}
                    style={{ width: "100%" }}
                  >
                    <Link className="sidenav-link" to="/signout">
                      Sign out
                    </Link>
                  </motion.div>
                </MDBSideNavCollapse>
              </MDBSideNavItem>

              <MDBSideNavItem>
                <motion.div
                  whileHover={{
                    x: 50,
                    scale: 1.2,
                    transition: { duration: 0.25 },
                  }}
                  whileTap={{ scale: 0.9 }}
                  style={{ width: "100%" }}
                >
                  <MDBSideNavLink
                    icon="angle-down"
                    onClick={handleAdminCollapse}
                  >
                    <MDBIcon fas icon="cogs" className="fa-fw me-3" />
                    Admin
                  </MDBSideNavLink>
                </motion.div>
                <MDBSideNavCollapse show={adminCollapse}>
                  <motion.div
                    whileHover={{
                      x: 50,
                      scale: 1.2,
                      transition: { duration: 0.25 },
                    }}
                    whileTap={{ scale: 0.9 }}
                    style={{ width: "100%" }}
                  >
                    <Link className="sidenav-link" to="/users">
                      User management
                    </Link>
                  </motion.div>
                  <motion.div
                    whileHover={{
                      x: 50,
                      scale: 1.2,
                      transition: { duration: 0.25 },
                    }}
                    whileTap={{ scale: 0.9 }}
                    style={{ width: "100%" }}
                  >
                    <Link className="sidenav-link" to="/roles">
                      Role management
                    </Link>
                  </motion.div>
                  <motion.div
                    whileHover={{
                      x: 50,
                      scale: 1.2,
                      transition: { duration: 0.25 },
                    }}
                    whileTap={{ scale: 0.9 }}
                    style={{ width: "100%" }}
                  >
                    <Link className="sidenav-link" to="/logs">
                      Log management
                    </Link>
                  </motion.div>
                  <motion.div
                    whileHover={{
                      x: 50,
                      scale: 1.2,
                      transition: { duration: 0.25 },
                    }}
                    whileTap={{ scale: 0.9 }}
                    style={{ width: "100%" }}
                  >
                    <Link className="sidenav-link" to="/not-found404">
                      404 page
                    </Link>
                  </motion.div>
                </MDBSideNavCollapse>
              </MDBSideNavItem>
            </>
          ) : (
            <div></div>
          )}

          <MDBSideNavItem className="text-center" style={{ marginTop: "24px" }}>
            <motion.div
              whileHover={{ scale: 1, rotate: 90 }}
              whileTap={{
                scale: 1,
                rotate: -90,
                borderRadius: "100%",
              }}
            >
              <img
                src={CozyHouse}
                alt="CozyHouse"
                style={{ width: 180, height: 180 }}
              />
            </motion.div>
          </MDBSideNavItem>
        </MDBSideNavMenu>
      </MDBSideNav>
      <div ref={sidenavContent} className="d-flex my-5 text-start">
        <main style={{marginTop:"24px", overflowX:"hidden"}}
     
        >
         
          <Suspense fallback={<div>Loading...</div>} >
            <AppRoutes />
          </Suspense>
        </main>
        <footer className={`mt-5`}>
          <Footer />
        </footer>
      </div>
    </>
  );
}
