import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// Helper function to handle local storage
function localStorageHelper() {
  return {
    getToken: () => localStorage.getItem('token') || "",
    setToken: (token: string) => localStorage.setItem('token', token),
    removeToken: () => localStorage.removeItem('token'),
  };
}


export const initialState = {
  loading: false,
  userInfo: {}, // for user object
  userToken: localStorageHelper().getToken(),
  error: null,
  success: false, // for monitoring the registration process.
};

const authSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // Reducer for receiving the user token
    tokenReceived: (state, action) => {

      // console.log('TOKEN HAS BEEN RECEIVED' + action.payload);
      state.userToken = action.payload;
      localStorageHelper().setToken(action.payload);
    },
    // Reducer for logging out the user
    loggedOut: (state) => {
      localStorageHelper().removeToken();
      state.userToken = "";
    }
  },
  extraReducers: {},
});


// Extract and export each action creator by name
export const { tokenReceived, loggedOut } = authSlice.actions;
// Export the reducer, either as a default or named export
export default authSlice.reducer;
