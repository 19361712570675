import React, { Suspense, useEffect, useState } from "react";

//Nodemodules/react-scripts/config/webpack.config.js
//https://www.npmjs.com/package/react-toastify
import "react-toastify/dist/ReactToastify.css";
import Navbar from "./components/navbars/Navbar";

import { store, useAppDispatch } from "shared";
import { BrowserRouter as Router } from "react-router-dom";
import Sidenav from "components/navbars/SideNav";
import { useCookies } from "react-cookie";
import { ToastContainer } from "react-toastify";
const App = () => {

  const [basicOpen, setBasicOpen] = useState(true);

  const [cookies, setCookie, removeCookie] = useCookies(["dark"]);
  const dispatch = useAppDispatch();

  store.subscribe(() => {
    const state = store.getState();
    document.cookie = `dark=${state.theme["darkMode"]}`;
  });

  useEffect(() => {
    let isDarkModeCookie : boolean = cookies["dark"] === "true" ? true : false;


    dispatch({ type: "theme/toggleTheme", payload: isDarkModeCookie });
  },[]);

  return (
    <>
        <Router>
            <div style={{'marginBottom':24}}>

          <Navbar toggleSideNav={setBasicOpen} sideNavState={basicOpen} />
          </div>

            <Sidenav basicOpen={basicOpen} setBasicOpen={setBasicOpen}/>
         
          
        </Router>
      <ToastContainer autoClose={2000} />

    </>
  );
};

export default App;
