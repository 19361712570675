import {
    ChangePasswordRequest,
  ConfirmEmailRequest,
  DeleteUserDTO,
  LoginResponse,
  LoginUserDTO,
  ResetPasswordRequest,
  UserDTO,
} from "~/types/authentication";
import { baseApi } from "./baseApi";

//Mutation should be used for anything which changes the data on the server

export const passwordApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    forgotPassword: builder.mutation<void, string>({
      query: (email) => ({
        url: `/password/forgotPassword?email=${email}`,
        method: "POST",
        credentials: "include",
        //Mutations require the invalidatesTags instead of providesTags
        invalidatesTags: ["Users"],
      }),
      invalidatesTags: ["Users"],

      transformResponse: (response: void, meta) => {
        return response;
      },

      transformErrorResponse: (response, meta, arg) => {
        return {
          originalArg: arg,
          error: response,
        };
      },
    }),
    confirmPasswordReset: builder.mutation<any, ResetPasswordRequest>({
      query: (request) => ({
        url: `/password/confirmForgotPasswordReset`,
        method: "POST",
        credentials: "include",
        body: request,
        //Mutations require the invalidatesTags instead of providesTags
        invalidatesTags: ["Users"],
      }),
      invalidatesTags: ["Users"],

      transformResponse: (response: void, meta) => {
        return response;
      },
      transformErrorResponse: (response, meta, arg) => {
        //Toast.show("unable to confirm email because " + JSON.stringify(response));

        return {
          originalArg: arg,
          error: response,
        };
      },
    }),
    changePassword: builder.mutation<void, ChangePasswordRequest>({
        query: (request) => ({
          url: `/password/changePassword`,
          method: "POST",
          credentials: "include",
        body: request,
          invalidatesTags: ["Users"],
        }),
        invalidatesTags: ["Users"],
  
        transformResponse: (response: void, meta) => {
          return response;
        },
  
        transformErrorResponse: (response, meta, arg) => {
          return {
            originalArg: arg,
            error: response,
          };
        },
      }),
  }),
});

export const { useConfirmPasswordResetMutation, useForgotPasswordMutation, useChangePasswordMutation } =
  passwordApi;
