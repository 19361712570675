
import { createApi } from '@reduxjs/toolkit/query/react'
import customFetchBase from './customFetchBase'




//https://redux-toolkit.js.org/rtk-query/api/created-api/hooks#usemutation

//Mutation should be used for anything which changes the data on the server

export const baseApi = createApi({
  baseQuery: customFetchBase,
 tagTypes: ["Users","Logs", "Roles", "Business"],

  endpoints: () => ({})

})


