/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import { useRoutes } from 'react-router-dom';

import { publicRoutes } from './Public';
import { protectedRoutes } from './Protected';
import { useAppSelector } from 'shared/';

export const AppRoutes = () => {

    const signedIn = useAppSelector(state => state.auth.userToken)


  if(signedIn){
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return<>{useRoutes([...publicRoutes, ...protectedRoutes])}</>
  }
  else{
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return<>{useRoutes([...publicRoutes])}</>

  }

};