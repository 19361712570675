
import { cloudFlareUploadRequest } from "~/types/cloudFlare";

import { baseApi } from "../baseApi";


export const cloudFlareApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
//	presignedUrl	"https://97b0e4ac-da4f-4598-9080-93424ad673e1.e208a3abb92e2b21d902b46df0483dbc.r2.cloudflarestorage.com/Reactive.svg?AWSAccessKeyId=3f3d8e897f4e5ce57e2ca55ab93cc851&Expires=1680126758&Signature=VFC7erAFHF48LnGdA90RJ1yYMJE%3D"	
uploadImageToR2: builder.mutation<any, cloudFlareUploadRequest>({
    query: (uploadRequest) => ({
      url: `${uploadRequest.preSignedUrl}`,
      method: "PUT",
      body: uploadRequest.file,
      headers: {
        "Origin": "http://localhost:3001",
        "Content-Type": uploadRequest.file.type 
      },
    }),
  
    transformResponse: (response: any, meta) => {
      console.log("Success");
      return response;
    },
    transformErrorResponse: (response, meta, arg) => {
      return {
        originalArg: arg,
        error: response,
      };
    },
  }),
  
  
//	e	{System.Net.WebException: The remote server returned an error: (401) Unauthorized.    at System.Net.HttpWebRequest.GetResponse()    at SilverMenu.DependencyInjections.S3.S3Helper.GetContents(String path) in /Users/waiiki/Desktop/SilverMenu_Api/SilverMenu/DependencyInjections/S3/S3Helper.cs:line 97}	
   //	url	"https://97b0e4ac-da4f-4598-9080-93424ad673e1.e208a3abb92e2b21d902b46df0483dbc.r2.cloudflarestorage.com/seo.png?AWSAccessKeyId=2dcc74ea905672d8ed96fda4e730b5a0&Expires=1680195836&Signature=zp88xDrYaPLDg6guIReZ57e2mQU%3D"	
   //	path	"https://test-bucket.e208a3abb92e2b21d902b46df0483dbc.r2.cloudflarestorage.com/seo.png?AWSAccessKeyId=2dcc74ea905672d8ed96fda4e730b5a0&Expires=1680201260&Signature=GMFp9S43yDSdUVbjbpuegHOHgRQ%3D"	
   //	url	"https://test-bucket.s3.eu-central-1.amazonaws.com/wavy_blue.svg?X-Amz-Expires=900&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=2dcc74ea905672d8ed96fda4e730b5a0/20230330/eu-central-1/s3/aws4_request&X-Amz-Date=20230330T202817Z&X-Amz-SignedHeaders=host&X-Amz-Signature=ae69ebe34bc0b2af11eb2536571a02c6d2155f2b4ac8dcdfe9af7dcf98002495"	
  }),
});

export const {
  useUploadImageToR2Mutation
} = cloudFlareApi;
