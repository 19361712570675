// store/reducers/themeSlice.js

import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState = {
  darkMode: false,
};

export const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    toggleTheme: (state, action: PayloadAction<boolean>) => {

      state.darkMode = action.payload;

    if(!state.darkMode){
       document.body.classList.remove("darkMode");
       document.body.classList.add("lightMode");
       

      }
      else{
       document.body.classList.remove("lightMode");

         document.body.classList.add("darkMode");
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { toggleTheme } = themeSlice.actions;

export default themeSlice.reducer;