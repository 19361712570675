import React from 'react';
import {
  MDBFooter,
  MDBContainer,
  MDBCol,
  MDBRow,
  MDBIcon,
  MDBBtn
} from 'mdb-react-ui-kit';

export default function Footer() {
  return (
    <MDBFooter className='text-center fixed-bottom'>
      <MDBContainer className='pt-4'>
        <section className='mb-4'>
   

        
        </section>
      </MDBContainer>

      <div className='text-center  p-3' style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)', paddingTop:128 }}>
        © 2023 Copyright:
        <a href='https://www.RainierApps.com'>
          Rainier Apps
        </a>
      </div>
    </MDBFooter>
  );
}