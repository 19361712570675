  import { PaginationQuery } from "~/types/forms";
import moment from "moment";
  import { baseApi } from "./baseApi";
import { DeleteLogDTO, LogDTO, LogsQueryResponse } from "~/types/logsTypes";
  //Mutation should be used for anything which changes the data on the server
  
  export const logsApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
      getLogs: builder.query<LogsQueryResponse, PaginationQuery>({
        query: ({ page = 1, resultsPerPage = 2 }) => ({
          url: `/Logs?page=${page}&pageSize=${resultsPerPage}`,
          credentials: "include",
        }),
        providesTags: ["Logs"],
  

        transformResponse: (response: LogsQueryResponse, meta) => {
          response.logs.map((log: LogDTO) => {
            // Use the moment library to parse the timestamp and convert it to the desired format
            const timestamp = moment(log.timeStamp, "YYYY-MM-DDTHH:mm:ss.SSSSSSSZ").format("DD/MM/YYYY HH:mm:ss");
            log.timeStamp = timestamp;
          })
          return response;
        },
        transformErrorResponse: (response, meta, arg) => {
         // toast.error("unable to fetch logs");
          return {
            originalArg: arg,
            error: response,
          };
        },
        
      }),
      deleteLog: builder.mutation<void, DeleteLogDTO>({
        query: (deleteLog) => ({
          url: "/logs/delete",
          method: "DELETE",
          body: deleteLog,
          credentials: "include",
        }),
        transformResponse: (response: void, meta) => {

          return response;
        },
        transformErrorResponse: (response, meta, arg) => {

          return {
            originalArg: arg,
            error: response,
          };
        },
        invalidatesTags: ["Logs"],
      }),

      deleteAllLogs: builder.mutation<void, null>({
        query: (deleteLog) => ({
          url: "/logs/deleteAll",
          method: "DELETE",
          credentials: "include",
        }),
        transformResponse: (response: void, meta) => {
          
          return response;
        },
        transformErrorResponse: (response, meta, arg) => {
          return {
            originalArg: arg,
            error: response,
          };
        },
        invalidatesTags: ["Logs"],
      }),

      deleteManyLogs: builder.mutation<void, number[]>({
        query: (ids) => ({
          url: "/logs/deleteMany",
          body: ids,
          method: "DELETE",
          credentials: "include",
        }),
        transformResponse: (response: void, meta) => {
          
          return response;
        },
        transformErrorResponse: (response, meta, arg) => {
          return {
            originalArg: arg,
            error: response,
          };
        },
        invalidatesTags: ["Logs"],
      }),
      
    }),
  });
  
  export const {
    useGetLogsQuery,
    useDeleteLogMutation,
    useDeleteAllLogsMutation,
    useDeleteManyLogsMutation

  } = logsApi;
  