import { RoleDTO, RoleInfoQueryResponse } from "~/types/authentication";
import { baseApi } from "./baseApi";

//Mutation should be used for anything which changes the data on the server

export const rolesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getRoles: builder.query<RoleDTO[], void>({
      query: (x) => ({
        url: "/Roles",
        credentials: "include",
        providesTags: ["Roles"]
      }),
      providesTags: ["Roles"],
    }),
    createRole: builder.mutation<void, string>({
      query: (name) => ({
        url: `/Roles/create?name=${name}`,
        method: "POST",
        credentials: "include",
      }),
      transformResponse: (response: void, meta) => {
        return response;
      },
      transformErrorResponse: (response, meta, arg) => {
        return {
          originalArg: arg,
          error: response,
        };
      },
      invalidatesTags: ["Roles"],
    }),
    deleteRole: builder.mutation<void, RoleDTO>({
      query: (deleteRole) => ({
        url: "/Roles/delete",
        method: "DELETE",
        body: deleteRole,
        credentials: "include",
      }),
      transformResponse: (response: void, meta) => {
        return response;
      },
      transformErrorResponse: (response, meta, arg) => {
        return {
          originalArg: arg,
          error: response,
        };
      },
      invalidatesTags: ["Roles"],
    }),
    getRoleInfo: builder.query<RoleInfoQueryResponse, string>({
      query: (name) => ({
        url: `/Roles/getRoleInfo?roleName=${name}`,
        credentials: "include",
        providesTags: ["Roles"]
      }),
      providesTags: ["Roles"],
    }),
  }),
});

export const {
  useCreateRoleMutation,
  useGetRolesQuery,
  useDeleteRoleMutation,
  useGetRoleInfoQuery,
} = rolesApi;
