import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../authSlice"
import { baseApi } from "../../api/baseApi";
import themeSlice from "../themeSlice";

export const store = configureStore({
    reducer:{

        auth: authReducer,
        theme: themeSlice,
    [baseApi.reducerPath]: baseApi.reducer

    },

    middleware: (getDefaultMiddleware)=>
    getDefaultMiddleware({serializableCheck: false}).concat(
        baseApi.middleware)
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;