import { lazy } from 'react';
const ChangePassword = lazy(() => import('authentication/routes/ChangePassword'));

const LogsManagement = lazy(() => import('logging/LogsManagement'));

const RoleManagement = lazy(() => import('roles/RoleManagement'));
const UsersManagement = lazy(() => import('users/UsersManagement'));

const Signout = lazy(() => import('authentication/routes/Signout'));
const DashBoard = lazy(() => import('business/dashboard/Dashboard'));
const CreateBusiness = lazy(() => import('business/createBusiness/CreateBusiness'));



export const protectedRoutes = [
  

  {
    path: '/signout',
    element: <Signout />,
  },


  { path: '/roles', element: <RoleManagement /> },

  { path: '/logs', element: <LogsManagement /> },
  { path: '/users', element: <UsersManagement /> },
  { path: '/changePassword', element: <ChangePassword /> },
  { path: '/dashBoard', element: <DashBoard /> },
  { path: '/createBusiness', element: <CreateBusiness /> },





];