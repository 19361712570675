import {
  AlterUserRoleDTO,
  CreateUserDTO,
  DeleteUserDTO,
  LoginResponse,
  LoginUserDTO,
  UpdateUserDTO,
  UserDTO,
  UsersQueryResponse,
} from "~/types/authentication";

import { baseApi } from "./baseApi";
import { PaginationQuery } from "~/types/forms";

//Mutation should be used for anything which changes the data on the server

export const usersApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({

    createAccount: builder.mutation<void, UserDTO>({
      query: (newUser) => ({
        url: "/Account/createAccount",
        method: "POST",
        body: newUser,
        credentials: "include",
        //Mutations require the invalidatesTags instead of providesTags
      invalidatesTags:["Users"],
        
      }),
      invalidatesTags:["Users"],
 
      transformResponse: (response: void, meta) => {
        return response;
      },
      transformErrorResponse: (response, meta, arg) => {

        return {
          originalArg: arg,
          error: response,
        };
      },
    }),
 




    getUsers: builder.query<UsersQueryResponse, PaginationQuery>({
      query: ({ page = 1, resultsPerPage = 2 }) => ({
        url: `/Account/users?page=${page}&pageSize=${resultsPerPage}`,
        credentials: "include",
        providesTags: ["Users"]
      }),
      providesTags: ["Users"],
     
      transformResponse: (response: UsersQueryResponse, meta) => {
        console.log("Response is " + JSON.stringify(response));
        return response;
      },
      transformErrorResponse: (response, meta, arg) => {
        return {
          originalArg: arg,
          error: response,
        };
      },
      
    }),
    addUser: builder.mutation<void, CreateUserDTO>({
      query: (newUser) => ({
        url: "/Account/register",
        method: "POST",
        body: newUser,
        credentials: "include",
        //Mutations require the invalidatesTags instead of providesTags
      invalidatesTags:["Users"],
        
      }),
      invalidatesTags:["Users"],
 
      transformResponse: (response: void, meta) => {
        return response;
      },
      transformErrorResponse: (response, meta, arg) => {

        return {
          originalArg: arg,
          error: response,
        };
      },
    }),
 

    updateUser: builder.mutation<void, UpdateUserDTO>({
      query: (updateUser) => ({
        url: "/Account/update",
        method: "PUT",
        body: updateUser,
        credentials: "include",
      invalidatesTags: ["Users"],

      }),
      invalidatesTags:["Users"],

    }),
    deleteUser: builder.mutation<void, DeleteUserDTO>({
      query: (deleteUser) => ({
        url: "/Account/delete",
        method: "DELETE",
        body: deleteUser,
        credentials: "include",
      invalidatesTags: ["Users"],

      }),
      invalidatesTags: ["Users"],

      transformResponse: (response: void, meta) => {
        return response;
      },
      transformErrorResponse: (response, meta, arg) => {
        return {
          originalArg: arg,
          error: response,
        };
      },
    }),

    ////ROLES

    getRolesForUser: builder.query<string[], string>({
      query: (email) => ({
        url: `/Account/getroles?email=${email}`,
        credentials: "include",
        providesTags: ["Users"]
      }),
      providesTags: ["Users"],
     
      transformResponse: (response: string[], meta) => {
        return response;
      },
      transformErrorResponse: (response, meta, arg) => {
        return {
          originalArg: arg,
          error: response,
        };
      },
      
    }),
    addUserToRole: builder.mutation<void, AlterUserRoleDTO>({
      query: (dto) => ({
        url: `/Account/addToRole?email=${dto.email}&roleName=${dto.role}`,
        method: "POST",
        credentials: "include",
        //Mutations require the invalidatesTags instead of providesTags
      invalidatesTags:["Users","Roles"],
      
        
      }),
      invalidatesTags:["Users","Roles"],
 
      transformResponse: (response: void, meta) => {
        return response;
      },
      transformErrorResponse: (response, meta, arg) => {

        return {
          originalArg: arg,
          error: response,
        };
      },
    }),
    removeUserFromRole: builder.mutation<void, AlterUserRoleDTO>({
      query: (dto) => ({
        url: `/Account/removeFromRole?email=${dto.email}&roleName=${dto.role}`,
        method: "POST",
        credentials: "include",
        //Mutations require the invalidatesTags instead of providesTags
      invalidatesTags:["Users","Roles"],
        
      }),
      invalidatesTags:["Users","Roles"],
 
      transformResponse: (response: void, meta) => {
        return response;
      },
      transformErrorResponse: (response, meta, arg) => {

        return {
          originalArg: arg,
          error: response,
        };
      },
    }),
  }),
});

export const {
  useCreateAccountMutation,
  useGetUsersQuery,
  useAddUserMutation,
  useUpdateUserMutation,
  useDeleteUserMutation,
  useGetRolesForUserQuery,
  useAddUserToRoleMutation,
  useRemoveUserFromRoleMutation
} = usersApi;
