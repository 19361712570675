import { lazy, Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { MDBSpinner } from 'mdb-react-ui-kit';

// const  LogsManagement  = lazy(()=> import('logs/LogsManagement'));

const  Privacy  = lazy(()=> import('routes/Privacy'));
const  Portfolio  = lazy(()=> import('routes/Portfolio/Porfolio'));

const  About  = lazy(()=> import('routes/About/About'));


const  Login  = lazy(()=> import('authentication/routes/Login/Login'));
const  Register  = lazy(()=> import('authentication/routes/Registration/Register'));

const  Landing  = lazy(()=> import('routes/Landing/Landing'));
const  Pricing  = lazy(()=> import('routes/Pricing/Pricing'));


const  ForgotPassword  = lazy(()=> import('authentication/routes/ForgotPassword'));
const  ResetPassword  = lazy(()=> import('authentication/routes/ResetPassword'));




const App = () => {
  return (
      <Suspense
        fallback={
          <div className="h-full w-full flex items-center justify-center">
            <MDBSpinner />
          </div>
        }
      >
        <Outlet />
      </Suspense>
  );
};

export const publicRoutes = [
  
  { path: '/', element: <Landing /> },
  { path: '/Pricing', element: <Pricing /> },

  { path: '/Portfolio', element: <Portfolio /> },

 
  { path: '/login', element: <Login /> },
  { path: '/register', element: <Register /> },

  { path: '/privacy', element: <Privacy /> },
  { path: '/about', element: <About /> },


 
  { path: '/forgot-password', element: <ForgotPassword /> },
  { path: '/reset-password', element: <ResetPassword /> },



      { path: '*', element: <Navigate to="." /> },
    
  
];