import {
  autoCompleteRequest,
  autoCompleteResponse,
} from "~/types";

import { baseApi } from "../baseApi";

//https://docs.developer.yelp.com/docs/fusion-intro
export const yelpApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    autoComplete: builder.query<autoCompleteResponse, autoCompleteRequest>({

      query: (request) => ({

        url: `/yelp/autocomplete?name=${request.name}&latitude=${request.latitude}&longitude=-122.3131146`,
        credentials: "include",
      }),
      transformResponse: (response: autoCompleteResponse) => {
        console.log("Response is " + JSON.stringify(response));
        return response;
      },
      transformErrorResponse: (response, meta, arg) => {
        return {
          originalArg: arg,
          error: response,
        };
      },
      
    }),
    
  }),
});

export const { useAutoCompleteQuery } = yelpApi;
