import { bannerUploadRequest, BusinessDTO, CreateBusinessDTO, ImageUploadSignatureRequest } from "~/types";
import { signatureResponse } from "~/types/cloudFlare";

import { baseApi } from "../baseApi";


export const businessApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({

    doesBusinessExist: builder.query<any, string>({
      query: (businessName) => ({
        url: `/Business/doesBusinessExist?businessName=${businessName}`,
        credentials: "include",
        providesTags: ["Business"],
      }),
      providesTags: ["Business"],
    }),
    createBusiness: builder.mutation<void, CreateBusinessDTO>({
      query: (newBusiness) => ({
        url: "/Business/createBusiness",
        method: "POST",
        body: newBusiness,
        credentials: "include",
        //Mutations require the invalidatesTags instead of providesTags
        invalidatesTags: ["Business"],
      }),
      invalidatesTags: ["Business"],

      transformResponse: (response: void, meta) => {
        return response;
      },
      transformErrorResponse: (response, meta, arg) => {
        return {
          originalArg: arg,
          error: response,
        };
      },
    }),
    updateBusiness: builder.mutation<void, BusinessDTO>({
      query: (business) => ({
        url: `/Business/updateBusiness`,
        method: "PUT",
        body: business,
        credentials: "include",
        invalidatesTags: ["Business"],
      }),
      invalidatesTags: ["Business"],
    }),


    getBusinessesForUser: builder.query<BusinessDTO[], string>({
      query: (businessId) => ({
        url: `/Business/getBusinessesForUser?userId=${businessId}`,
        credentials: "include",
        providesTags: ["Business"],
      }),
      providesTags: ["Business"],
    }),


    getBusiness: builder.query<BusinessDTO[], void>({
      query: () => ({
        url: `/Business/getBusiness`,
        credentials: "include",
        providesTags: ["Business"],
      }),
      providesTags: ["Business"],
    }),

   
   

 
    deleteBusiness: builder.mutation<void, string>({
      query: (businessId) => ({
        url: `/Business/delete${businessId}`,
        method: "DELETE",
        credentials: "include",
        invalidatesTags: ["Business"],
      }),
      invalidatesTags: ["Business"],

      transformResponse: (response: void, meta) => {
        return response;
      },
      transformErrorResponse: (response, meta, arg) => {
        return {
          originalArg: arg,
          error: response,
        };
      },
    }),

    uploadBanner: builder.mutation<void, bannerUploadRequest>({
      query: (bannerUploadRequest) => ({
        url: `/Business/uploadBanner`,
        method: "POST",
        body: bannerUploadRequest,
        credentials: "include",
      }),
    }),

    getUploadSignature: builder.mutation<signatureResponse, ImageUploadSignatureRequest>({
      query: (request) => ({
        url: `/Business/getUploadSignature`,
        body: request,
        method: "POST",

        credentials: "include",
      }),
    }),

  }),

});

export const {
  useCreateBusinessMutation,
  useUpdateBusinessMutation,
  useDeleteBusinessMutation,
  useGetBusinessesForUserQuery,
  useGetBusinessQuery,
  useDoesBusinessExistQuery,
  useUploadBannerMutation,
  useGetUploadSignatureMutation
} = businessApi;
